
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import "bootstrap";

@import "jquery-uniform/themes/default/css/uniform.default";
@import "select2/dist/css/select2";
@import "datatables.net-dt/css/jquery.dataTables";
@import "datatables.net-buttons-bs/css/buttons.bootstrap";
@import "datatables.net-fixedheader-bs/css/fixedHeader.bootstrap";
@import "./../stylesheets/vendor/space.scss";
@import "./../stylesheets/vendor/smart-forms/smart-forms.scss";
@import "./../stylesheets/vendor/smart-forms/smart-addons.scss";

$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";

@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/light.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "@fortawesome/fontawesome-pro/scss/duotone.scss";

@import "./../stylesheets/_custom.scss";
