.page-content {
  min-height: 100vh;
}

a {
  color: #4f01a2;
}

.page-sidebar-menu ul li.active-page>a {
  color: #4f01a2;
}

.page-sidebar-menu ul li.active-page>a i {
  color: #4f01a2;
}

.clearfix {
  clear: both;
}

.mobile_screen_only {
  background-color: #F7F9FA;
}

@media (min-width: 992px) {
  .mobile_screen_only {
    position: fixed;
    bottom: 10px;
  }
}

@media (max-height: 920px) {
  .mobile_screen_only {
    display: none;
  }
}

.page-sidebar-fixed .page-sidebar,
.page-header-fixed .page-sidebar,
.page-sidebar-fixed.page-header-fixed .page-sidebar {
  padding-top: 115px;
}

#sidebar-toggle-button-close {
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: -30px;
  cursor: pointer;
  height: 18px;
}

@media (max-width: 991px) {
  .page-error .page-inner {
    vertical-align: middle;
    padding: 0;
  }
}

@media (min-width: 991px) {
  .navbar.navbar-default {
    .container-fluid {
      background-color: #fff;
      float: right;
    }
  }
}

.turbolinks-progress-bar {
  background-color: #0070E0;
}

.monospaced_font {
  font-family: monospace;

  & span {
    font-family: monospace;
  }
}

.login-page {
  .logout_btn {
    padding: 8px 15px;
    color: #5f5f5f;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }

  background: url('../images/data_science.jpg') no-repeat center center fixed;
}

.main_application#main-wrapper {
  margin-bottom: 2rem;
  padding-bottom: 20px
}

.page-footer {
  color: #737373;
  font-size: 0.8rem;
}

.dashboard-stats {
  color: #637282;
}

.menu-icon-fa {
  margin-right: 10px;
  font-size: 17px;
  vertical-align: middle;
  line-height: 22px;
}

.page-header .navbar-nav>li>a {
  font-size: 16px;

  span.navbar_email {
    text-transform: lowercase;
    font-size: 14px;
    padding-right: 10px;
  }
}

.page-header {
  padding-top: 0px !important;
  height: 0px !important;
  margin: 0px !important;
}

.page-inner {
  margin-top: 42px !important;
  padding-top: 0px !important;
}

@media screen and (max-width: 991px) {
  .page-inner {
    padding-top: 1px !important;
  }
}

.page-inner.login-page {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.navbar-default {
  height: 6px !important;
}

.navbar-collapse {
  height: 6px !important;
}

.navbar {
  min-height: 10px !important;
}

#rootwizard {
  ul {
    li {
      margin-bottom: 0;
    }
  }
}

.top_buttons_section {
  .btn {
    margin-right: 10px;
  }
}

.stats-widget {
  min-height: 178px;
  padding: 24px;
}

hr {
  border-color: #eeeeee;
}

.btn-sm {
  font-size: 14px;
}

.panel-table {
  padding: 0px !important;
  border-color: white !important;
}

.btn-success {
  color: #5cb85c !important;
  background-color: white !important;
  border-color: #4cae4c !important;
}

.btn-success:hover,
.btn-success:active:hover,
.btn-success.active:hover {
  border-color: #4cae4c !important;
  background-color: #e8f1eb !important;
}

.btn-danger {
  border-color: #f5707a !important;
  color: #f5707a !important;
  background-color: white !important;
}

.btn-danger:hover,
.btn-danger:active:hover,
.btn-danger.active:hover {
  border-color: #f5707a !important;
  background-color: #f3cdd0 !important;
}








.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(207, 207, 207);
  border-radius: 0px;
  min-height: 42px;

  .select2-selection__rendered {
    line-height: 25px;
  }

  .select2-selection__arrow {
    /*height:40px;*/
    display: none;
  }
}

.select2-container {
  width: 100% !important;
}

.select2-selection--multiple {
  padding: 10px
}

.smart-forms .multiple_col_radio {
  margin-right: 0;
}

.dt-buttons.btn-group {
  float: right !important;
  margin-left: 20px !important;
}

.smart-forms .gui-input[disabled],
.smart-forms .gui-textarea[disabled],
.smart-forms .select>select[disabled],
.smart-forms .select-multiple select[disabled],
.smart-forms .gui-input[disabled]~.input-hint {
  color: #000000;
}

.smart-forms input[disabled]+.radio,
.smart-forms input[disabled]+.checkbox {
  cursor: default;
  opacity: 0.75;
}

.select2-container--default.select2-container--disabled .select2-selection--single,
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #f4f6f6;
  border-color: #d5dbdb !important;
  /* opacity: 0.7;*/
}

.select2-selection__clear {
  font-size: 20px;
  color: #9F9F9F;
  margin-top: -3px;
}


.status_icon {
  color: #d3d1d1;
  cursor: pointer;
}

.smart-forms {
  input[type="submit"].btn-lg {
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1.3333333;
  }
}






.formValign {
  margin-bottom: 15px 0 !important;
  display: block !important;
  width: 100% !important;
  float: left !important;
  color: pink;
}

/* When Rails add field_with_errors around the issue*/
.smart-forms .prepend-icon input,
.smart-forms .prepend-icon textarea,
.smart-forms .prepend-icon select {
  padding-left: 36px;
}

.smart-forms field_with_errors .gui-input,
.smart-forms field_with_errors .gui-textarea,
.smart-forms field_with_errors.select>select,
.smart-forms field_with_errors.select-multiple>select {
  background: #FEE9EA;
  border-color: #DE888A;
}





@media screen and (min-width: 1777px) {
  .info_table {

    tr th,
    tr td {
      button:last-child {
        margin-left: 10px;
        margin-top: 0px;
      }
    }
  }
}

.info_table {
  tr {

    th,
    td {
      vertical-align: middle;

      .m-b-sm {
        margin-bottom: 10px;
        margin-right: 10px
      }

      button:last-child {
        margin-left: 0px; /* margin-top: 10px;*/
      }
    }

    td:last-child {
      /* max-width: 60px;*/
      text-align: right;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #F7F9FA;
      }
    }
  }
}

#user_overview_table {
  tr {
    td:last-child {
      text-align: left;
    }
  }
}

table.dataTable {
  border: 0px !important;

  thead {
    .sorting_disabled.sorting::after {
      opacity: 0.2;
      content: "";
      background-image: none !important;
    }
  }

  .btn-sm {
    padding: 2px 10px;
  }

  tbody th,
  tbody td {
    padding: 4px 10px;
  }

  &.display tbody tr.even>.sorting_1,
  &.order-column.stripe tbody tr.even>.sorting_1,
  &.stripe tbody tr.odd,
  &.display tbody tr.odd {
    background-color: #F7F9FA;
  }
}

// for column_visibility
.dt-button-collection.dropdown-menu>li>a:focus {
  background: inherit;
}

.dt-button-collection.dropdown-menu>li>a:hover {
  background: #ddd;
}

.dt-button-collection.dropdown-menu>li.active {
  background-color: #f7f7f7;
}

.dataTables_length,
.dataTables_info {
  float: left;
}

tfoot {
  //display: table-header-group;
}

.tableColorComplete {
  color: rgb(30, 179, 0) !important;
}

.tableColorIncomplete {
  color: rgb(255, 199, 0) !important;
}

.tableColorOverdue {
  color: rgb(236, 51, 35) !important;
}

.tableColorNotStarted {
  color: #c7c7c7 !important;
}

tfoot select {
  width: 100%;
  max-width: 90%;
}

tfoot input {
  width: 100%;
  max-width: 90%;
}

.fa.icoCompelte {
  color: green !important;
  background-color: white;
}




.studyHeader {
  margin-left: -15px;
  background-color: #d9e7f9;
  padding: 15px;
  color: black;
}

.minMriLabel {
  .field-label {
    min-height: 76.47px !important;
  }
}

#subjID {
  text-transform: uppercase;
}
.buttonGroup1{
  display: inline-block;
  float:left;
  vertical-align: top; 
}
.buttonGroup2{
  display: inline-block;
  vertical-align: top; 
  float: none;
}
.hiddenInput{
  display:none;
}
.crfWarning {
  background-color: #ffeed4 !important;
}
.crfWarningIcon {
  color: #ffedd4 !important;
}
thead select {
  width: 100%;
  max-width: 90%;
}
thead input {
  width: 100%;
  max-width: 90%;
}
.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 16px;
}
.btn-anchor:after {
  font-family: "Glyphicons Halflings";
  content: "\e114";
  float: right;
  margin-left: 15px;
}
/* Icon when the collapsible content is hidden */
.btn-anchor.collapsed:after {
  content: "\e080";
}
select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;

  .select2-selection {
    background: #eee;
    box-shadow: none;
  }

  .select2-selection__arrow,
  .select2-selection__clear {
    display: none;
  }
}
.block {
  display: block;
}
.hoverRow:hover{
  background: #e6f1fb;
}
.signature_pad {
  width: 700px;
  height: 400px;
  margin: 0 auto;
}
.signature_pad_body canvas {
  width: 100%;
  height: 100%;
  border: dotted #444;
  background: #cccccc;
}
p.notice {
  font-family: arial;
  font-size: 14px;
  line-height: 18px;
}

* {
  box-sizing: border-box;
}

.html_wrapper {
  width: 100%;
  position: relative;
}

.canvas_container {
  border: 1px solid #adadad;
  position: relative;
  background: #fff;
  margin-top: 10px;
}

.canvas_container_left {
  border-right: 1px solid #adadad;
}

.canvas_container_left,
.canvas_container_right {
  float: left;
  width: 50%;
  position: relative;
}

.canvas_container:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.sig_checkbox_wrapper {
  background: #f3faff;
  padding: 20px 18px 15px 18px;
  border: 1px solid #adadad;
  border-top: none;
  display: flex;
}

.sig_checkbox_wrapper:after {
  clear: both;
  display: block;
  content: " ";
  height: 0;
}

#sig_checkbox {
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
  margin-right: 15px;
  /*to increase size of checkbox, see: https://stackoverflow.com/a/10415275/1063287*/;
}

.sig_statement {
  display: inline-block;
  font-family: arial;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
  color: #8a8a8a;
  line-height: 27px;
  margin-bottom: -5px;
  margin-top: -5px;
}

.sig_checkbox_left_col {
  float: left;
}

.sig_checkbox_right_col {
  float: left;
}

.terms_and_conditions_external_link {
  color: #8a8a8a;
}

.clear_sig_button {
  color: #8a8a8a;
  font-family: arial;
  padding: 3px 10px;
  border: 1px solid #ececec;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 30px;
  z-index: 2;
}

.clear_sig_button:hover {
  cursor: pointer !important;
}
.save_sig_button {
  color: #8a8a8a;
  font-family: arial;
  padding: 3px 10px;
  border: 1px solid #ececec;
  text-align: center;
  border-radius: 2px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
}

.save_sig_button:hover {
  cursor: pointer !important;
}

.sig_print_wrapper {
  background: #eee;
}

.printed_name {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #adadad;
  border-left: none;
  border-right: none;
  border-bottom: none;
  font-size: 15px;
  color: #555;
}
.previously_signed {
  width: 100%;
  border: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 10px;
  margin: 0;
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #626262;
  outline: none;
}

#customer_printed_name {
  width: 50%;
  float: left;
  border-right: 1px solid #adadad;
}

#customer_printed_position {
  width: 50%;
  float: left;
}

.m-signature-pad {
  width: 100%;
  height: 100px;
}

.m-signature-pad--body {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.m-signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 40px);
}